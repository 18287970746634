import logo from './logo.png';
import './App.css';

function App() {
	return (
		<div className="App">
			<header className="App-header">
				<img src={logo} className="App-logo" alt="logo" />
				<p>
					Welcome to the server I setup to host our project, Grouper. This page is scripted in /home/mern/client/src/App.js.
				</p>
			</header>
		</div>
	);
}

export default App;
